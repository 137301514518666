<template>
	<teleport to="body">
		<div class="dialog-contain">
			<transition name="toggle-overlay">
				<div v-if="isOpen" class="screen-overlay" @click="closeDialog"></div>
			</transition>

			<transition name="toggle-dialog">
				<div v-if="isOpen" class="dialog text-center">
					<slot>
						<h4>{{ text }}</h4>
					</slot>
					<br />
					<div class="dialog-buttons">
						<button type="button" class="btn-link" @click="closeDialog" ref="cancelButton">{{ cancelText }}</button>
						<button type="button" :class="setButtonClass" @click="confirmDialog" ref="confirmButton">
							<span class="button-text" :class="{ hidden: loading }">{{ confirmText }}</span>
							<loading-indicator type="dots" v-if="loading" />
						</button>
					</div>
				</div>
			</transition>
		</div>
	</teleport>
</template>

<script>
import MessageType from '@/models/MessageType';

export default {
	data() {
		return {
			isOpen: false,
			type: false,
			loading: false,
			closeOnConfirm: false,
			text: '',
			confirmText: '',
			cancelText: '',
			resolvePromise: null,
		};
	},
	computed: {
		MessageType() {
			return MessageType;
		},
		setButtonClass() {
			if (this.type === MessageType.success || this.type === MessageType.info) {
				return 'btn-confirm';
			} else if (this.type === MessageType.warning || this.type === MessageType.error) {
				return 'btn-warn';
			} else {
				return 'btn-confirm';
			}
		},
	},
	methods: {
		openDialog(text = '', { type = 'info', confirmText = 'Confirm', cancelText = 'Cancel', closeOnConfirm = false } = {}) {
			this.resolve(false);
			this.text = text;
			this.type = type;
			this.loading = false;
			this.closeOnConfirm = closeOnConfirm;
			this.confirmText = typeof confirmText === 'string' && confirmText ? confirmText : 'Confirm';
			this.cancelText = typeof cancelText === 'string' && cancelText ? cancelText : 'Cancel';
			this.isOpen = true;
			this.$nextTick(() => {
				if (this.$refs.confirmButton) {
					this.$refs.confirmButton.focus();
				}
			});
			return new Promise((resolve) => {
				this.resolvePromise = resolve;
			});
		},
		closeDialog() {
			this.isOpen = false;
			this.resolve(false);
		},
		confirmDialog() {
			if (this.closeOnConfirm) {
				this.isOpen = false;
			} else {
				this.loading = true;
			}
			this.resolve(true);
		},
		resolve(value) {
			if (this.resolvePromise !== null) {
				const resolve = this.resolvePromise;
				this.resolvePromise = null;
				resolve(value);
			}
		},
	},
};
</script>
