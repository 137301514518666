import Permission from '@/models/Permission';
import { parseDateTimeProp, parseIntProp } from '../_helpers';

export default {
	path: '/Portal/Operator',
	component: () => import('@/views/operator/_OperatorApp.vue'),
	meta: { appName: 'operator', requiresAuth: true, requiresCompanyUser: true, requiresOperator: true, permissions: [Permission.companyOperator], blockOffline: false, },
	children: [
		{
			path: '',
			component: () => import('@/views/operator/DashboardPage.vue'),
			meta: { title: 'My service orders', requiresPunchIn: true, isHomePage: true, },
		},
		{
			path: 'LocalChanges',
			component: () => import('@/views/operator/LocalChangesPage.vue'),
			meta: { title: 'Unsynced changes', },
		},
		{
			path: 'VehicleReport',
			component: () => import('@/views/operator/VehicleReportPage.vue'),
			meta: { title: 'Vehicle report', },
			props: route => ({
				date: parseDateTimeProp(route.query.date),
				vehicleId: parseIntProp(route.query.vehicleId),
			}),
		},
		{
			path: 'Timesheets/Punch',
			component: () => import('@/views/operator/TimesheetsPunchPage.vue'),
			props: route => ({ referrer: route.query.referrer, }),
			meta: { title: 'Timeclock', },
		},
		{
			path: 'WasteDisposal',
			component: () => import('@/views/operator/WasteDisposalPage.vue'),
			meta: { title: 'Waste disposal', requiresPunchIn: true, },
			props: route => ({
				filter: route.query.filter,
				sort: route.query.sort,
			}),
		},
		{
			path: 'WasteDisposal/Details/:id(-?\\d+)',
			component: () => import('@/views/operator/WasteDisposalDetailsPage.vue'),
			meta: { title: 'Waste wisposal details', requiresPunchIn: true, },
			props: route => ({ id: parseIntProp(route.params.id) }),
		},
		{
			path: 'WasteDisposal/Edit/:id(-?\\d+)',
			component: () => import('@/views/operator/WasteDisposalEditPage.vue'),
			meta: { title: 'Waste disposal', requiresPunchIn: true, },
			props: route => ({ id: parseIntProp(route.params.id), siteId: parseIntProp(route.query.siteId) }),
		},
		{
			path: 'WorkOrders/Edit/:id(\\d+)',
			component: () => import('@/views/operator/WorkOrderEditPage.vue'),
			meta: { title: 'Service order', requiresPunchIn: true, },
			props: route => ({ id: parseIntProp(route.params.id) }),
		},
		{
			path: 'WorkOrders/Invoice/:id(\\d+)',
			component: () => import('@/views/operator/InvoicePage.vue'),
			meta: { title: 'Invoice', requiresPunchIn: true, },
			props: route => ({ id: parseIntProp(route.params.id) }),
		},
	]
};
