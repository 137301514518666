<template>
	<teleport to="body">
		<div class="dialog-contain">
			<transition name="toggle-overlay">
				<div v-if="isOpen" class="screen-overlay" @click="closeDialog"></div>
			</transition>

			<transition name="toggle-dialog">
				<div v-if="isOpen" class="dialog text-center">
					<h4>{{ model.heading }}</h4>
					<p>{{ model.message }}</p>
					<p class="text-danger" v-show="!canDelete"><strong>{{ model.cannotDeleteMessage }}</strong></p>
					<br />
					<div class="dialog-buttons">
						<button type="button" class="btn-link" @click="closeDialog" :disabled="loading">Cancel</button>
						<button type="button" class="btn-warn" @click="confirmDialog" :disabled="loading || !canDelete">
							<span class="button-text" :class="{ hidden: loading }">{{ model.confirmText || 'Delete' }}</span>
							<loading-indicator type="dots" v-if="loading" />
						</button>
					</div>
				</div>
			</transition>
		</div>
	</teleport>
</template>

<script>
import { fetchWrap } from '@/api/_helpers';
import { handleHttpError } from '@/helpers/data';

export default {
	data() {
		return {
			isOpen: false,
			hasError: false,
			loading: false,
			canDelete: true,
			model: {},
			resolvePromise: null,
		}
	},
	methods: {
		openDialog(model) {
			this.resolve(false);
			this.model = model;
			this.hasError = false;
			this.loading = false;
			this.canDelete = true;
			this.isOpen = true;
			return new Promise((resolve) => {
				this.resolvePromise = resolve;
			});
		},
		closeDialog() {
			if (!this.loading) {
				this.isOpen = false;
				this.resolve(false);
			}
		},
		confirmDialog() {
			this.loading = true;
			this.hasError = false;
			this.canDelete = true;
			if (this.model.id > 0 && this.model.deleteUrl) {
				fetchWrap(this.model.deleteUrl, { method: 'DELETE' }).then(response => {
					this.loading = false;
					if (response.ok || response.status === 404) {
						this.isOpen = false;
						this.resolve(true);
					} else if (response.status === 409) {
						this.canDelete = false;
					} else {
						throw Error(response.statusText);
					}
				}).catch(error => {
					this.loading = false;
					this.hasError = true;
					handleHttpError(error);
				});
			} else {
				this.loading = false;
				this.isOpen = false;
				this.resolve(true);
			}
		},
		resolve(value) {
			if (this.resolvePromise !== null) {
				const resolve = this.resolvePromise;
				this.resolvePromise = null;
				resolve(value);
			}
		},
	},
};
</script>
