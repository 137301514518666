import { getValueFromDto as getValue, getDateTimeFromDto as getDate, getObjectFromDto as getObject } from './_helpers.js';
import Duration from './Duration.js';

export default class UserCecRequirement {
	constructor(dto) {
		this.id = getValue(dto, 'id', 'number', 0);
		this.description = getValue(dto, 'description', 'string', '');
		this.renewalDate = getDate(dto, 'renewalDate', null);
		this.renewalTerm = getObject(dto, 'renewalTerm', Duration, new Duration());
		this.renewalWarning = getObject(dto, 'renewalWarning', Duration, new Duration());
		this.creditsRequired = getValue(dto, 'creditsRequired', 'number', 0);
		this.creditsEarned = getValue(dto, 'creditsEarned', 'number', 0);
	}
}
