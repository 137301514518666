import accountApi from '@/api/modules/account';
import permissionsApi from '@/api/modules/permissions';
import settingsApi from '@/api/modules/settings';
import timeClockPunchesApi from '@/api/modules/timeClockPunches';
import { setLuxonTimeZone } from '@/helpers/helpers';
import { setUser as setLoggingUser } from '@/helpers/logging';
import { getIdb, localChangesInUse, setLocalChangesInUse } from '@/idb';
import Message from '@/models/Message';
import MessageType from '@/models/MessageType';
import Permission from '@/models/Permission';
import Settings from '@/models/Settings';
import User from '@/models/User';
import { DateTime } from 'luxon';
import { defineStore, mapStores } from 'pinia';

let env = 'production';
if (window.location.hostname === 'localhost' || /\.ngrok((-free)?\.(app|dev)|\.io)$/.test(window.location.hostname)) {
	env = 'development'
} else if (/^nitrogen\d\.inetwd\.com$/.test(window.location.hostname)) {
	env = 'testing'
} else if (/^www(blue|green)\.\w+\.\w+$/.test(window.location.hostname)) {
	env = 'staging'
}

const state = () => ({
	debugging: env === 'development' && import.meta.env.MODE === 'development',
	environment: env,
	user: new User(),
	lastTimeClockPunch: null,
	lastTimeClockPunchLoaded: false,
	settings: null,
	isOnline: true,
	installPrompt: null,
	showInstallBanner: false,
	showInstallButton: false,
	serviceWorkerUpdate: false,
	messages: [],
	lastCacheRefresh: null,
	localChangesSyncing: false,
	localChangesCount: 0,
});

const getters = {
	isLoggedIn: (state) => state.user !== null && state.user.id > 0 && state.user.active,
	isSuperAdmin(state) {
		return this.isLoggedIn && (state.user.companyId === null || state.user.isImpersonating);
	},
	isUser(state) {
		return this.isLoggedIn && state.user.companyId > 0;
	},
	isOperator() {
		return this.isUser && this.user.operator && this.userHasThisPermission(Permission.companyOperator);
	},
};

const actions = {
	userHasThisPermission(permission) {
		return this.isLoggedIn && (
			this.user.permissions.includes(permission) ||
			this.user.permissions.includes(Permission.adminAccessAll) ||
			(this.user.permissions.includes(Permission.companyAccessAll) && !permissionsApi.isAdminPermission(permission))
		);
	},
	userHasThisModule(module) {
		return this.isLoggedIn && this.user.modules.includes(module);
	},
	async refresh() {
		await this.refreshBeforeMount();
		await this.refreshAfterMount();
	},
	async refreshBeforeMount() {
		await this.refreshAuth();
		await this.refreshSettings();
	},
	async refreshAfterMount() {
		await Promise.all([
			this.refreshSettings(),
			this.refreshLastTimeClockPunch(),
			this.refreshCacheIfNeeded(),
		]);
	},
	async refreshAuth() {
		const user = await accountApi.getSelf();
		if (user instanceof User) {
			this.user = user;
		} else {
			this.user = new User();
		}
		setLoggingUser(this.user);
		await setLocalChangesInUse(this.isOperator);
	},
	async refreshLastTimeClockPunch() {
		if (this.isUser && !this.isSuperAdmin) {
			this.lastTimeClockPunch = await timeClockPunchesApi.getMyLastPunch();
		} else {
			this.lastTimeClockPunch = null;
		}
		this.lastTimeClockPunchLoaded = true;
	},
	async refreshSettings() {
		const settings = this.isUser ? await settingsApi.getSettings() : null;

		if (settings !== null && settings instanceof Settings) {
			this.settings = settings;
		} else {
			this.settings = null;
		}

		// set time zone
		setLuxonTimeZone(this.settings ? this.settings.timeZone : null);
	},
	async refreshCacheIfNeeded() {
		const idb = localChangesInUse.value ? await getIdb() : null;
		if (idb) {
			if (!this.lastCacheRefresh) {
				const dIso = await idb.get('localInfo', 'lastCacheRefresh');
				const d = dIso ? DateTime.fromISO(dIso) : null;
				this.lastCacheRefresh = d && d.isValid ? d : null;
			}
			if (navigator.serviceWorker && (!this.lastCacheRefresh || this.lastCacheRefresh < DateTime.now().minus({ hours: 1 }))) {
				navigator.serviceWorker.ready.then(function (worker) {
					worker.active.postMessage('sync-local-changes');
				});
			}
		} else {
			this.lastCacheRefresh = null;
		}
	},
	removeMessage(messageId) {
		const index = this.messages.findIndex(x => x.id === messageId);
		if (index >= 0) {
			this.messages.splice(index, 1);
		}
	},
	addMessage(messageText, type = MessageType.info, autoClose = true) {
		if (!(typeof messageText === 'string')) { return; }
		messageText = messageText.trim();
		if (!messageText) { return; }

		const currentId = this.messages.length > 0 ? this.messages.reduce((id, x) => Math.max(id, x.id), 0) : 0;
		const message = new Message();
		message.id = currentId + 1;
		message.type = MessageType.isValid(type) ? type : MessageType.info;
		message.message = messageText;
		message.autoClose = !!autoClose;

		if (this.messages.findIndex(x => x.id === message.id) >= 0) { return; }
		this.messages.push(message);
		if (autoClose) {
			setTimeout(() => {
				this.removeMessage(message.id);
				// 1s delay for animate out:
			}, Message.AUTO_CLOSE_DELAY + 1000);
		}
	},
	setCustomPwaBanner(status) {
		if (status && localStorage.getItem('pwaUserChoice') != 'no') {
			this.showInstallBanner = true;
		} else {
			this.showInstallBanner = false;
		}
	},
	setPwaPrompt(event) {
		if (event != null) {
			this.installPrompt = event;
		}
	},
};

let _storeInUse = false;
const _useStore = defineStore('general', {
	state,
	getters,
	actions,
});

export function storeInUse() {
	return _storeInUse;
}

export function useStore() {
	_storeInUse = true;
	return _useStore();
}

export function mapStore() {
	const { generalStore } = mapStores(_useStore);
	return { $store: generalStore };
}
