import { DateTime } from 'luxon';

export function setupStore($store) {
	// Update online and offline status
	function updateOnlineStatus() {
		$store.isOnline = navigator.onLine;
	}
	updateOnlineStatus();
	window.addEventListener('online', updateOnlineStatus);
	window.addEventListener('offline', updateOnlineStatus);

	// PWA install banner
	window.addEventListener('beforeinstallprompt', (e) => {
		e.preventDefault();
		$store.showInstallBanner = localStorage.getItem('pwaUserChoice') != 'no';
		$store.installPrompt = e;
	});

	const broadcast = new BroadcastChannel('sync');
	broadcast.addEventListener('message', event => {
		if (typeof event.data === 'object' && event.data !== null) {
			if (event.data.key === 'lastCacheRefresh') {
				const d = event.data.value ? DateTime.fromISO(event.data.value) : null
				$store.lastCacheRefresh = d && d.isValid ? d : null;
			} else if (event.data.key === 'localChangesSyncing') {
				$store.localChangesSyncing = event.data.value;
			} else if (event.data.key === 'localChangesCount') {
				$store.localChangesCount = event.data.value;
			}
		}
	});
	if (navigator.serviceWorker) {
		navigator.serviceWorker.ready.then(worker => {
			worker.active.postMessage('rebroadcast-localChangesSyncing');
			worker.active.postMessage('rebroadcast-localChangesCount');
		});
	}
}
