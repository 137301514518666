import { DateTime } from 'luxon';
import { getDateTimeFromDto as getDate, getValueFromDto as getValue } from './_helpers.js';

export default class FileReference {
	constructor(dto) {
		this.id = getValue(dto, 'id', 'number', 0);
		this.name = getValue(dto, 'name', 'string', '');
		this.size = getValue(dto, 'size', 'number', 0);
		this.url = getValue(dto, 'url', 'string', '');
		this.uploadTimestamp = getDate(dto, 'uploadTimestamp', null);
		this.data = getValue(dto, 'data', 'object', null);

		Object.defineProperty(this, 'name', { enumerable: false });
		Object.defineProperty(this, 'size', { enumerable: false });
		Object.defineProperty(this, 'url', { enumerable: false });
		Object.defineProperty(this, 'uploadTimestamp', { enumerable: false });
		Object.defineProperty(this, 'data', { enumerable: false });

		this.url = this.url || FileReference.getUrl(this.id);
	}

	get extension() {
		return FileReference.getExtension(this.name);
	}

	/**
	 * @param {string} name
	 * @returns {string} lowercase file extension without extension
	 */
	static getExtension(name) {
		if (name) {
			const index = name.lastIndexOf('.');
			if (index >= 0 && index < name.length - 1) {
				return name.substr(index + 1).toLowerCase();
			}
			return null;
		} else {
			return null;
		}
	}

	static getUrl(id) {
		return typeof id === 'number' && id !== 0 && !isNaN(id) ? '/api/Files/Download/' + id : '';
	}

	toDto() {
		return {
			id: this.id,
			name: this.name,
			size: this.size,
			url: this.url,
			uploadTimestamp: this.uploadTimestamp instanceof DateTime ? this.uploadTimestamp.toISO() : null,
		};
	}
}
