import { url as urlHelper } from '@/helpers/helpers';
import Company from '@/models/Company';
import { fetchWrap, offlineResponse } from '../_helpers';

export default {
	/**
	 * Get all companies
	 * @param {Object} params request parameters.
	 * @param {Boolean} params.active if specified, only return either active or inactive companies.
	 * @returns {Promise<Company[]>} (async) Returns an array of Companys if the request was successful, otherwise a Response.
	 */
	async getAll({ active = undefined } = {}) {
		const query = {};
		if (typeof active === 'boolean') {
			query.active = active;
		}
		const url = urlHelper('/api/Companies', query);
		let response;
		try {
			response = await fetchWrap(url);
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return (await response.json()).map(x => new Company(x));
		} else {
			throw response;
		}
	},
	/**
	 * Get a company
	 * @param {Number} id Company ID
	 * @returns (async) Returns a Company if the request was successful, otherwise a Response.
	 */
	async getById(id) {
		let response;
		try {
			response = await fetchWrap('/api/Companies/' + id);
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return new Company(await response.json());
		} else {
			throw response;
		}
	},
	/**
	 * Create a company
	 * @param {Company} model company to create.
	 * @returns (async) Returns the new Company if the request was successful, otherwise a Response.
	 */
	async create(model) {
		let response;
		try {
			response = await fetchWrap('/api/Companies', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return new Company(await response.json());
		} else {
			return response;
		}
	},
	/**
	 * Update a company
	 * @param {Company} model company to update.
	 * @returns (async) Returns the updated Company if the request was successful, otherwise a Response.
	 */
	async update(model) {
		let response;
		try {
			response = await fetchWrap('/api/Companies/' + model.id, {
				method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return new Company(model);
		} else {
			return response;
		}
	},
	/**
	 * Delete a company
	 * @param {Number} id Company ID to delete.
	 * @returns (async) Returns true if the request was successful (or not found), false if the company could not be deleted, otherwise a Response.
	 */
	async deleteById(id) {
		let response;
		try {
			response = await fetchWrap('/api/Companies/' + id, { method: 'DELETE' });
		} catch {
			return offlineResponse();
		}
		if (response.ok || response.status === 404) {
			return true;
		} else if (response.status === 409) {
			return false;
		} else {
			return response;
		}
	}
};
