import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import { BrowserTracing } from '@sentry/browser';
import { init as initSentry, setUser as setSentryUser, Replay, makeBrowserOfflineTransport, makeFetchTransport } from '@sentry/vue';
import { initGoogleAnalytics } from './googleAnalytics';
import { escapeRegex } from './helpers';

let sentryInitialized = false;
export function useLogging(app, $store, $router) {
	if ($store.environment === 'production' && !$store.debugging) {
		initGoogleAnalytics({ router: $router });
	}
	if ($store.environment !== 'development' && !$store.debugging) {
		const currentOrigin = window.location.origin.toLowerCase();
		if (sentryInitialized) return;
		sentryInitialized = true;
		initSentry({
			app: app,
			dsn: "https://03a53fda8f7d4d2080bacefcb0ad7a2e@o229809.ingest.sentry.io/6660614",
			environment: $store.environment,
			release: __APP_VERSION__,
			beforeBreadcrumb(breadcrumb) {
				let drop = false;
				// drop = drop || breadcrumb.category === "console";
				drop = drop || ((breadcrumb.category === "fetch" || breadcrumb.category === "xhr") && (new URL(breadcrumb.data.url, currentOrigin)).origin.toLowerCase() !== currentOrigin);
				return drop ? null : breadcrumb;
			},
			beforeSend(event, hint) {
				const e = hint.originalException;
				return e instanceof DOMException && e.name === 'AbortError' ? null : event;
			},
			allowUrls: [
				new RegExp('^' + escapeRegex(currentOrigin + '/'), 'i'),
			],
			integrations: [
				new Replay({
					maskAllText: true,
					blockAllMedia: true,
				}),
				new BrowserTracing({
					tracingOrigins: [
						new RegExp('^' + escapeRegex(currentOrigin + '/'), 'i'),
					],
					routingInstrumentation: (startTransaction) => {
						$router.beforeEach((to, from) => {
							if (to.path !== '/' && from.fullPath !== to.fullPath) {
								let transactionName = to.path;
								let transactionSource = 'url';
								if (to.matched.length > 0) {
									transactionName = to.matched[to.matched.length - 1].path.replaceAll(/(\/:\w+)\([^)]+\)/g, '$1');
									transactionSource = 'route';
								}
								startTransaction({
									name: transactionName,
									op: 'navigation',
									tags: {
										'routing.instrumentation': 'vue-router',
									},
									data: {
										params: to.params,
										query: to.query,
									},
									metadata: {
										source: transactionSource,
									},
								});
							}
						});
					},
				}),
				new CaptureConsoleIntegration({
					levels: ['error', 'warn'],
				}),
			],
			transport: makeBrowserOfflineTransport(makeFetchTransport),
			transportOptions: {
				maxQueueSize: 1000,
			},
			trackComponents: true,
			replaysSessionSampleRate: 0,
			replaysOnErrorSampleRate: 1,
			tracesSampler(context) {
				if ($store.environment !== 'production') return 0;
				if (context.parentSampled !== undefined) return context.parentSampled;
				let keep = $store.isLoggedIn && !$store.user.isInternal;
				if (keep && context.transactionContext && context.transactionContext.op === 'navigation') {
					const path = context.transactionContext.name.toLowerCase();
					keep = path === '/portal' || path.startsWith('/portal/');
				}
				if (!keep) { return 0; }
				return 0.2;
			},
		});
	}
}

export function setUser(user) {
	if (!sentryInitialized) return;
	const userInfo = {
		ip_address: '{{auto}}',
	};
	if (user && user.id > 0) {
		userInfo.email = user.emailAddress;
		userInfo.id = user.id;
		userInfo.ip_address = '{{auto}}';
		userInfo.username = user.name;
		if (user.companyId) {
			userInfo.companyId = user.companyId;
		}
	}
	setSentryUser(userInfo);
}
