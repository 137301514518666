import Permission from '@/models/Permission';
import { parseBoolProp, parseDateTimeProp, parseIntProp } from '../_helpers';
import AppModule from '@/models/AppModule';

export default {
	path: '/Portal',
	component: () => import('@/views/portal/_PortalApp.vue'),
	meta: { blockOffline: true, appName: 'portal', requiresAuth: true, requiresCompanyUser: true, permissions: [Permission.companyOffice], },
	children: [
		{
			path: '',
			component: () => import('@/views/portal/DashboardPage.vue'),
			meta: { title: 'Dashboard', isHomePage: true, },
		},
		{
			path: 'Config',
			component: () => import('@/views/portal/config/HomePage.vue'),
			meta: { title: 'Settings', permissions: [Permission.companyOffice, Permission.companySettings], },
		},
		{
			path: 'Config/Roles',
			component: () => import('@/views/portal/config/RolesPage.vue'),
			meta: { title: 'Roles', permissions: [Permission.companyOffice, Permission.companySettings], },
		},
		{
			name: 'Config-Roles-Edit',
			path: 'Config/Roles/:id(\\d+)',
			component: () => import('@/views/portal/config/RoleEditPage.vue'),
			props: route => ({ id: parseIntProp(route.params.id) }),
			meta: { title: 'Role Edit', permissions: [Permission.companyOffice, Permission.companySettings], },
		},
		{
			path: 'Config/Roles/Edit',
			redirect: to => (parseInt(to.query.id) >= 0 ? { name: 'Config-Roles-Edit', params: { id: to.query.id, }, query: {} } : { path: '/404', query: {} }),
		},
		{
			path: 'Config/Services',
			component: () => import('@/views/portal/config/ServicesPage.vue'),
			meta: { title: 'Services', permissions: [Permission.companyOffice, Permission.companySettings], },
		},
		{
			name: 'Config-Services-Edit',
			path: 'Config/Services/:id(\\d+)',
			component: () => import('@/views/portal/config/ServiceEditPage.vue'),
			props: route => ({ id: parseIntProp(route.params.id) }),
			meta: { title: 'Service Edit', permissions: [Permission.companyOffice, Permission.companySettings], },
		},
		{
			path: 'Config/Services/Edit',
			redirect: to => (parseInt(to.query.id) >= 0 ? { name: 'Config-Services-Edit', params: { id: to.query.id, }, query: {} } : { path: '/404', query: {} }),
		},
		{
			path: 'Config/Inspections',
			component: () => import('@/views/portal/config/InspectionsPage.vue'),
			meta: { title: 'Inspections', permissions: [Permission.companyOffice, Permission.companySettings], },
		},
		{
			path: 'Config/PaymentTerms',
			component: () => import('@/views/portal/config/PaymentTermsPage.vue'),
			meta: { title: 'Payment Terms', permissions: [Permission.companyOffice, Permission.companySettings], },
		},
		{
			path: 'Config/PaymentSettings',
			component: () => import('@/views/portal/config/PaymentSettingsPage.vue'),
			meta: { title: 'Payment Settings', permissions: [Permission.companyOffice, Permission.companySettings], },
		},
		{
			path: 'Config/CompanySettings',
			component: () => import('@/views/portal/config/CompanySettingsPage.vue'),
			meta: { title: 'Company Settings', permissions: [Permission.companyOffice, Permission.companySettings], },
		},
		{
			path: 'Config/TankTypes',
			component: () => import('@/views/portal/config/TankTypesPage.vue'),
			meta: { title: 'Tank Types', permissions: [Permission.companyOffice, Permission.companySettings], },
		},
		{
			path: 'Config/VehicleMaintenanceTypes',
			component: () => import('@/views/portal/config/VehicleMaintenanceTypesPage.vue'),
			meta: { title: 'Vehicle Maintenance Types', permissions: [Permission.companyOffice, Permission.companySettings], },
		},
		{
			path: 'Config/VehicleTypes',
			component: () => import('@/views/portal/config/VehicleTypesPage.vue'),
			meta: { title: 'Vehicle Types', permissions: [Permission.companyOffice, Permission.companySettings], },
		},
		{
			path: 'Config/WasteDisposalSiteTypes',
			component: () => import('@/views/portal/config/WasteDisposalSiteTypesPage.vue'),
			meta: { title: 'Waste Disposal Site Types', permissions: [Permission.companyOffice, Permission.companySettings], },
		},
		{
			path: 'Config/WasteTypes',
			component: () => import('@/views/portal/config/WasteTypesPage.vue'),
			meta: { title: 'Waste Types', permissions: [Permission.companyOffice, Permission.companySettings], },
		},
		{
			path: 'Config/RentalItemTypes',
			component: () => import('@/views/portal/config/RentalItemTypePage.vue'),
			meta: { title: 'Rental Item Types', permissions: [Permission.companyOffice, Permission.companySettings], modules: [AppModule.rentals], },
		},
		{
			path: 'RentalItems',
			component: () => import('@/views/portal/rental-item/RentalItemsPage.vue'),
			props: route => ({
				filter: route.query.filter,
			}),
			meta: { title: 'Rental Items', permissions: [Permission.companyOffice, Permission.companyRentals], },
		},
		{
			name: 'RentalItem-Details',
			path: 'RentalItems/:id(\\d+)',
			component: () => import('@/views/portal/rental-item/RentalItemDetailsPage.vue'),
			props: route => ({
				id: parseIntProp(route.params.id),
				startDate: parseDateTimeProp(route.query.startDate),
				endDate: parseDateTimeProp(route.query.endDate),
				sort: route.query.sort,
				pageNumber: parseIntProp(route.query.pageNumber),
				pageSize: parseIntProp(route.query.pageSize),
			}),
			meta: { title: 'Rental Item Details', permissions: [Permission.companyOffice, Permission.companyRentals], },
		},
		{
			name: 'RentalItem-Edit',
			path: 'RentalItems/:id(\\d+)/Edit',
			component: () => import('@/views/portal/rental-item/RentalItemEditPage.vue'),
			props: route => ({ id: parseIntProp(route.params.id) }),
			meta: { title: 'Rental Item Edit', permissions: [Permission.companyOffice, Permission.companyRentals], },
		},
		{
			path: 'CustomerRentals-Attention',
			component: () => import('@/views/portal/customer-rental/CustomerRentalAttentionPage.vue'),
			props: route => ({
				pageNumber: parseIntProp(route.query.pageNumber),
				pageSize: parseIntProp(route.query.pageSize),
			}),
			meta: { title: 'Customer Rental Need Attention', permissions: [Permission.companyOffice, Permission.companyRentals], },
		},
		{
			path: 'CustomerRentals',
			component: () => import('@/views/portal/customer-rental/CustomerRentalsPage.vue'),
			props: route => ({
				selectedDate: parseDateTimeProp(route.query.date),
				view: route.query.view,
				typeFilter: parseIntProp(route.query.typeFilter),
			}),
			meta: { title: 'Customer Rentals', permissions: [Permission.companyOffice, Permission.companyRentals], },
		},
		{
			name: 'CustomerRentals-Detail',
			path: 'CustomerRentals/:id(\\d+)',
			component: () => import('@/views/portal/customer-rental/CustomerRentalDetailPage.vue'),
			props: route => ({
				id: parseIntProp(route.params.id),
				pageNumber: parseIntProp(route.query.pageNumber),
				pageSize: parseIntProp(route.query.pageSize),
			}),
			meta: { title: 'Customer Rental Detail', permissions: [Permission.companyOffice, Permission.companyRentals], },
		},
		{
			path: 'Emails',
			component: () => import('@/views/portal/EmailsPage.vue'),
			props: route => ({
				pageNumber: parseIntProp(route.query.pageNumber),
				pageSize: parseIntProp(route.query.pageSize),
			}),
			meta: { title: 'Emails', permissions: [Permission.companyOffice], },
		},
		{
			path: 'Reports',
			component: () => import('@/views/portal/reports/ReportsPage.vue'),
			meta: { title: 'Reports', permissions: [Permission.companyOffice, Permission.companyReports], },
		},
		{
			path: 'Vehicles',
			component: () => import('@/views/portal/vehicles/VehiclesPage.vue'),
			props: route => ({
				filter: route.query.filter,
				sort: route.query.sort,
			}),
			meta: { title: 'Vehicles', },
		},
		{
			name: 'Vehicles-Details',
			path: 'Vehicles/:id(\\d+)',
			component: () => import('@/views/portal/vehicles/VehicleDetailsPage.vue'),
			props: route => ({ id: parseIntProp(route.params.id) }),
			meta: { title: 'Vehicle Details', },
		},
		{
			path: 'Vehicles/Details',
			redirect: to => (parseInt(to.query.id) >= 0 ? { name: 'Vehicles-Details', params: { id: to.query.id, }, query: {} } : { path: '/404', query: {} }),
		},
		{
			path: 'Timesheets',
			component: () => import('@/views/portal/timesheets/TimesheetsPage.vue'),
			props: route => ({
				selectedDate: parseDateTimeProp(route.query.date),
				filter: route.query.filter,
				sort: route.query.sort,
			}),
			meta: { title: 'Timesheets', permissions: [Permission.companyOffice, Permission.companyTimeSheets], },
		},
		{
			path: 'Timesheets/Punch',
			component: () => import('@/views/portal/timesheets/TimesheetsPunchPage.vue'),
			props: route => ({ referrer: route.query.referrer, }),
			meta: { title: 'Time Clock', },
		},
		{
			path: 'Users',
			component: () => import('@/views/portal/users/UsersPage.vue'),
			props: route => ({
				filter: route.query.filter,
				sort: route.query.sort,
			}),
			meta: { title: 'Users', permissions: [Permission.companyOffice, Permission.companyUsers], },
		},
		{
			name: 'Users-Details',
			path: 'Users/:id(\\d+)',
			component: () => import('@/views/portal/users/UserDetailsPage.vue'),
			props: route => ({ id: parseIntProp(route.params.id) }),
			meta: { title: 'User Details', permissions: [Permission.companyOffice, Permission.companyUsers], },
		},
		{
			path: 'Users/Details',
			redirect: to => (parseInt(to.query.id) >= 0 ? { name: 'Users-Details', params: { id: to.query.id, }, query: {} } : { path: '/404', query: {} }),
		},
		{
			path: 'Inventory',
			component: () => import('@/views/portal/inventory/InventoryPage.vue'),
			props: route => ({
				search: route.query.search,
				sort: route.query.sort,
				pageNumber: parseIntProp(route.query.pageNumber),
				pageSize: parseIntProp(route.query.pageSize),
			}),
			meta: { title: 'Inventory', },
		},
		{
			name: 'Inventory-Edit',
			path: 'Inventory/:id(\\d+)',
			component: () => import('@/views/portal/inventory/InventoryEditPage.vue'),
			props: route => ({ id: parseIntProp(route.params.id) }),
			meta: { title: 'Inventory Edit', },
		},
		{
			path: 'Inventory/Edit',
			redirect: to => (parseInt(to.query.id) >= 0 ? { name: 'Inventory-Edit', params: { id: to.query.id, }, query: {} } : { path: '/404', query: {} }),
		},
		{
			path: 'WorkOrders/Estimates',
			component: () => import('@/views/portal/work-orders/EstimatesPage.vue'),
			props: route => ({
				startDate: parseDateTimeProp(route.query.startDate),
				endDate: parseDateTimeProp(route.query.endDate),
				filter: route.query.filter,
				sort: route.query.sort,
				pageNumber: parseIntProp(route.query.pageNumber),
				pageSize: parseIntProp(route.query.pageSize),
			}),
			meta: { title: 'Estimates', },
		},
		{
			path: 'WorkOrders/Invoices',
			component: () => import('@/views/portal/work-orders/InvoicesPage.vue'),
			props: route => ({
				startDate: parseDateTimeProp(route.query.startDate),
				endDate: parseDateTimeProp(route.query.endDate),
				filter: route.query.filter,
				sort: route.query.sort,
				pageNumber: parseIntProp(route.query.pageNumber),
				pageSize: parseIntProp(route.query.pageSize),
			}),
			meta: { title: 'Invoices', },
		},
		{
			path: 'WorkOrders/Incomplete',
			component: () => import('@/views/portal/work-orders/IncompleteWorkOrdersPage.vue'),
			props: route => ({
				pageNumber: parseIntProp(route.query.pageNumber),
				pageSize: parseIntProp(route.query.pageSize),
			}),
			meta: { title: 'Incomplete Service Orders', },
		},
		{
			path: 'WorkOrders/Dispatch',
			component: () => import('@/views/portal/work-orders/DispatchPage.vue'),
			props: route => ({
				selectedDate: parseDateTimeProp(route.query.date),
				filter: parseIntProp(route.query.filter),
			}),
			meta: { title: 'Dispatch Calendar', },
		},
		{
			name: 'WorkOrders-Edit',
			path: 'WorkOrders/Edit/:id(\\d+)',
			component: () => import('@/views/portal/work-orders/WorkOrderEditPage.vue'),
			props: route => ({
				id: parseIntProp(route.params.id),
				status: parseIntProp(route.query.status),
				customerId: parseIntProp(route.query.customerId),
				customerRentalId: parseIntProp(route.query.customerRentalId),
				customerLocationId: parseIntProp(route.query.customerLocationId),
				duplicate: parseBoolProp(route.query.duplicate),
			}),
			meta: { title: 'Edit Service Order', workOrderPage: true, },
		},
		{
			path: 'WorkOrders/Edit',
			redirect: to => {
				return {
					name: 'WorkOrders-Edit',
					params: {
						id: to.query.id || '0',
					},
					query: {
						status: to.query.status,
						customerId: to.query.customerId,
						customerLocationId: to.query.customerLocationId,
						duplicate: to.query.duplicate,
					}
				}
			},
		},
		{
			name: 'WorkOrders-Complete',
			path: 'WorkOrders/Complete/:id(\\d+)',
			component: () => import('@/views/portal/work-orders/WorkOrderCompletePage.vue'),
			props: route => ({ id: parseIntProp(route.params.id) }),
			meta: { title: 'Service Order Details', workOrderPage: true, },
		},
		{
			path: 'WorkOrders/Complete',
			redirect: to => (parseInt(to.query.id) >= 0 ? { name: 'WorkOrders-Complete', params: { id: to.query.id, }, query: {} } : { path: '/404', query: {} }),
		},
		{
			name: 'WorkOrders-Details-Office',
			path: 'WorkOrders/Details/:id(\\d+)',
			component: () => import('@/views/portal/work-orders/WorkOrderDetailsPage.vue'),
			props: route => ({ id: parseIntProp(route.params.id) }),
			meta: { title: 'Invoice Details', workOrderPage: true, },
		},
		{
			path: 'WorkOrders/Details',
			redirect: to => (parseInt(to.query.id) >= 0 ? { name: 'WorkOrders-Details-Office', params: { id: to.query.id, }, query: {} } : { path: '/404', query: {} }),
		},
		{
			path: 'Customers',
			component: () => import('@/views/portal/customers/CustomersPage.vue'),
			props: route => ({
				filter: route.query.filter,
				sort: route.query.sort,
				pageNumber: parseIntProp(route.query.pageNumber),
				pageSize: parseIntProp(route.query.pageSize),
			}),
			meta: { title: 'Customers', },
		},
		{
			path: 'Customers/:id(\\d+)',
			component: () => import('@/views/portal/customers/_CustomerApp.vue'),
			props: route => ({ id: parseIntProp(route.params.id) }),
			children: [
				{
					path: '',
					component: () => import('@/views/portal/customers/CustomerDetailsPage.vue'),
					beforeEnter: to => {
						const path = to.path.endsWith('/') ? to.path : (to.path + '/');
						if (to.query.tab === 'workOrders' || to.query.tab === 'schedules') { return { path: path + 'WorkOrders', query: {} }; }
						if (to.query.tab === 'billing') { return { path: path + 'Billing', query: {} }; }
						if (to.query.tab === 'wasteDisposals') { return { path: path + 'WasteDisposals', query: {} }; }
					},
					props: route => ({
						id: parseIntProp(route.params.id),
					}),
					meta: { title: 'Customer Details', },
				},
				{
					path: 'WorkOrders',
					component: () => import('@/views/portal/customers/CustomerWorkOrdersPage.vue'),
					props: route => ({
						id: parseIntProp(route.params.id),
						startDate: parseDateTimeProp(route.query.startDate),
						endDate: parseDateTimeProp(route.query.endDate),
						location: parseIntProp(route.query.location),
						pageNumber: parseIntProp(route.query.pageNumber),
						pageSize: parseIntProp(route.query.pageSize),
					}),
					meta: { title: 'Customer Service Orders', },
				},
				{
					path: 'Rentals',
					component: () => import('@/views/portal/customers/CustomerRentalsPage.vue'),
					props: route => ({
						id: parseIntProp(route.params.id),
						location: parseIntProp(route.query.location),
						pageNumber: parseIntProp(route.query.pageNumber),
						pageSize: parseIntProp(route.query.pageSize),
						sort: route.query.sort,
					}),
					meta: { title: 'Customer Rentals', modules: [AppModule.rentals], },
				},
				{
					path: 'Billing',
					component: () => import('@/views/portal/customers/CustomerBillingPage.vue'),
					props: route => ({
						id: parseIntProp(route.params.id),
						startDate: parseDateTimeProp(route.query.startDate),
						endDate: parseDateTimeProp(route.query.endDate),
						pageNumber: parseIntProp(route.query.pageNumber),
						pageSize: parseIntProp(route.query.pageSize),
					}),
					meta: { title: 'Customer Billing', },
				},
				{
					path: 'WasteDisposals',
					component: () => import('@/views/portal/customers/CustomerWasteDisposalsPage.vue'),
					props: route => ({
						id: parseIntProp(route.params.id),
						startDate: parseDateTimeProp(route.query.startDate),
						endDate: parseDateTimeProp(route.query.endDate),
						location: parseIntProp(route.query.location),
						pageNumber: parseIntProp(route.query.pageNumber),
						pageSize: parseIntProp(route.query.pageSize),
					}),
					meta: { title: 'Customer Waste Disposals', },
				},
			],
		},
		{
			path: 'Customers/Details',
			redirect: to => {
				if (parseInt(to.query.id) >= 0) {
					return { path: '/Portal/Customers/' + to.query.id, query: { tab: to.query.tab } };
				} else {
					return { path: '/404', query: {} };
				}
			},
		},
		{
			name: 'Customer-Edit',
			path: 'Customers/Edit/:id(\\d+)',
			component: () => import('@/views/portal/customers/CustomerEditPage.vue'),
			props: route => ({ id: parseIntProp(route.params.id) }),
			meta: { title: 'Customer Edit', },
		},
		{
			path: 'Customers/Edit',
			redirect: to => (parseInt(to.query.id) >= 0 ? { name: 'Customer-Edit', params: { id: to.query.id, }, query: {} } : { path: '/404', query: {} }),
		},
		{
			path: 'WasteDisposal',
			component: () => import('@/views/portal/waste-disposal/WasteDisposalsPage.vue'),
			props: route => ({
				startDate: parseDateTimeProp(route.query.startDate),
				endDate: parseDateTimeProp(route.query.endDate),
				pageNumber: parseIntProp(route.query.pageNumber),
				pageSize: parseIntProp(route.query.pageSize),
			}),
			meta: { title: 'Waste Disposals', },
		},
		{
			name: 'WasteDisposal-Details',
			path: 'WasteDisposal/:id(\\d+)',
			component: () => import('@/views/portal/waste-disposal/WasteDisposalDetailsPage.vue'),
			props: route => ({ id: parseIntProp(route.params.id) }),
			meta: { title: 'Waste Disposal Details', },
		},
		{
			path: 'WasteDisposal/Details',
			redirect: to => (parseInt(to.query.id) >= 0 ? { name: 'WasteDisposal-Details', params: { id: to.query.id, }, query: {} } : { path: '/404', query: {} }),
		},
		{
			path: 'WasteDisposal/Create',
			component: () => import('@/views/portal/waste-disposal/WasteDisposalCreatePage.vue'),
			meta: { title: 'Log Waste Disposal', },
		},
		{
			path: 'DisposalSites',
			component: () => import('@/views/portal/disposal-sites/DisposalSitesPage.vue'),
			props: route => ({
				capacityFilter: route.query.capacityFilter,
				activeFilter: route.query.activeFilter,
				landFilter: route.query.landFilter,
				seasonFilter: route.query.seasonFilter,
				sort: route.query.sort,
				pageNumber: parseIntProp(route.query.pageNumber),
				pageSize: parseIntProp(route.query.pageSize),
			}),
			meta: { title: 'Disposal Sites', },
		},
		{
			name: 'DisposalSites-Details',
			path: 'DisposalSites/:id(\\d+)',
			component: () => import('@/views/portal/disposal-sites/DisposalSiteDetailsPage.vue'),
			props: route => ({ id: parseIntProp(route.params.id) }),
			meta: { title: 'Disposal Site Details', },
		},
		{
			path: 'DisposalSites/Details',
			redirect: to => (parseInt(to.query.id) >= 0 ? { name: 'DisposalSites-Details', params: { id: to.query.id, }, query: {} } : { path: '/404', query: {} }),
		},
	]
};
