import Permission from '@/models/Permission';
import { parseIntProp } from '../_helpers';

export default {
	path: '/Admin',
	component: () => import('@/views/admin/_AdminApp.vue'),
	meta: { requiresAuth: true, requiresSuperAdmin: true, permissions: [Permission.admin], stopImpersonation: true, blockOffline: true, appName: 'admin', },
	children: [
		{
			path: '',
			redirect: '/Admin/Companies'
		},
		{
			path: 'Companies',
			component: () => import('@/views/admin/CompaniesPage.vue'),
			meta: { title: 'Companies', isHomePage: true, },
		},
		{
			path: 'Users',
			component: () => import('@/views/admin/UsersPage.vue'),
			meta: { title: 'Users', permissions: [Permission.admin, Permission.adminUsers], },
		},
		{
			path: 'Users/:id(\\d+)',
			component: () => import('@/views/admin/UserEditPage.vue'),
			props: route => ({ id: parseIntProp(route.params.id) }),
			meta: { title: 'User Edit', permissions: [Permission.admin, Permission.adminUsers], },
		},
		{
			path: 'Roles',
			component: () => import('@/views/admin/RolesPage.vue'),
			meta: { title: 'Roles', permissions: [Permission.admin, Permission.adminRoles], },
		},
		{
			path: 'Roles/:id(\\d+)',
			component: () => import('@/views/admin/RoleEditPage.vue'),
			props: route => ({ id: parseIntProp(route.params.id) }),
			meta: { title: 'Role Edit', permissions: [Permission.admin, Permission.adminRoles], },
		},
		{
			path: 'Emails',
			component: () => import('@/views/admin/EmailsPage.vue'),
			props: route => ({
				pageNumber: parseIntProp(route.query.pageNumber),
				pageSize: parseIntProp(route.query.pageSize),
			}),
			meta: { title: 'Emails', permissions: [Permission.admin], },
		},
		{
			path: 'Utilities',
			component: () => import('@/views/admin/UtilitiesPage.vue'),
			meta: { title: 'Utilities', permissions: [Permission.admin], },
		},
		{
			path: 'Subscriptions',
			component: () => import('@/views/admin/SubscriptionsPage.vue'),
			meta: { title: 'Subscriptions', permissions: [Permission.admin], },
		},
		{
			path: 'Subscriptions/:id(\\d+)',
			component: () => import('@/views/admin/SubscriptionEditPage.vue'),
			props: route => ({ id: parseIntProp(route.params.id) }),
			meta: { title: 'Subscription Edit', permissions: [Permission.admin], },
		},
	]
};
