import { parseIntProp } from '../_helpers';

export default [
	{
		path: '/Account',
		component: () => import('@/views/account/_AccountApp.vue'),
		meta: { blockOffline: true, appName: 'account', },
		children: [
			{
				path: '',
				redirect: '/Account/Manage',
			},
			{
				path: 'ForgotPassword',
				component: () => import('@/views/account/ForgotPasswordPage.vue'),
				meta: { title: 'Forgot Password', requiresAnonymous: true, },
			},
			{
				path: 'Login',
				component: () => import('@/views/account/LoginPage.vue'),
				props: route => ({ referrer: route.query.referrer, }),
				meta: { title: 'Login', requiresAnonymous: true, isHomePage: true, isLoginPage: true },
			},
			{
				path: 'Manage',
				component: () => import('@/views/account/ManagePage.vue'),
				meta: { title: 'Manage Account', requiresAuth: true, },
			},
			{
				path: 'ResetPassword',
				component: () => import('@/views/account/ResetPasswordPage.vue'),
				props: route => ({ code: route.query.code, }),
				meta: { title: 'Reset Password', },
			},
			{
				path: 'Setup',
				component: () => import('@/views/account/SetupPage.vue'),
				props: route => ({ code: route.query.code, userId: parseIntProp(route.query.userId) }),
				meta: { title: 'Setup Account', },
			},
		]
	},
	{
		path: '/Account/ConfirmEmailChange',
		component: () => import('@/views/account/ConfirmEmailChangePage.vue'),
		meta: { blockOffline: true, appName: 'account', title: 'Confirm Email Change', },
		props: route => ({
			userId: parseIntProp(route.query.userId),
			email: route.query.email,
			code: route.query.code,
		}),
	},
];
