import UserCecRequirement from './UserCecRequirement.js';
import { getArrayFromDto as getArray, getArrayOfObjectsFromDto as getTypedArray, getDateTimeFromDto as getDate, getValueFromDto as getValue } from './_helpers.js';

export default class User {
	constructor(dto) {
		this.id = getValue(dto, 'id', 'number', 0);
		this.companyId = getValue(dto, 'companyId', 'number', null);
		Object.defineProperty(this, 'companyId', { enumerable: false });
		this.isImpersonating = getValue(dto, 'isImpersonating', 'boolean', false);
		Object.defineProperty(this, 'isImpersonating', { enumerable: false });
		this.permissions = getArray(dto, 'permissions', []);
		Object.defineProperty(this, 'permissions', { enumerable: false });
		this.modules = getArray(dto, 'modules', []);
		Object.defineProperty(this, 'modules', { enumerable: false });
		this.emailAddress = getValue(dto, 'emailAddress', 'string', '');
		this.emailConfirmed = getValue(dto, 'emailConfirmed', 'boolean', false);
		this.roles = getArray(dto, 'roles', []);
		this.cecRequirements = getTypedArray(dto, 'cecRequirements', UserCecRequirement, []);
		this.firstName = getValue(dto, 'firstName', 'string', '');
		this.middleInitial = getValue(dto, 'middleInitial', 'string', '');
		this.lastName = getValue(dto, 'lastName', 'string', '');
		this.title = getValue(dto, 'title', 'string', '');
		this.phoneNumber = getValue(dto, 'phoneNumber', 'string', '');
		this.operatorCertificationNumber = getValue(dto, 'operatorCertificationNumber', 'string', '');
		this.grade = getValue(dto, 'grade', 'string', '');
		this.departmentOfCommerceId = getValue(dto, 'departmentOfCommerceId', 'string', '');
		this.operator = getValue(dto, 'operator', 'boolean', false);
		this.wage = getValue(dto, 'wage', 'number', null);
		this.wageDate = getDate(dto, 'wageDate', null);
		this.active = getValue(dto, 'active', 'boolean', false);
		this.isPunchedIn = getValue(dto, 'isPunchedIn', 'boolean', false);
		this.isInternal = getValue(dto, 'isInternal', 'boolean', false);
	}

	get name() {
		return (this.firstName + ' ' + this.lastName).trim();
	}

	get initials() {
		return (this.firstName ? this.firstName.substr(0, 1) : '') + (this.middleInitial || '') + (this.lastName ? this.lastName.substr(0, 1) : '');
	}

	toString() {
		return `${this.firstName} ${this.lastName} (${this.initials})`;
	}
}
