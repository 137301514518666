import WorkOrderReference from './WorkOrderReference.js';
import { getDateTimeFromDto as getDate, getArrayOfObjectsFromDto as getTypedArray, getValueFromDto as getValue } from './_helpers.js';

export class CustomerRentalDetailedItemStatus {
	static noChange = 0;
	static deliver = 1;
	static pickUp = 2;
}

export class CustomerRentalDetailedNeed {
	constructor(dto) {
		this.id = getValue(dto, 'id', 'number', 0);
		this.customerRentalId = getValue(dto, 'customerRentalId', 'number', null);
		this.rentalItemTypeId = getValue(dto, 'rentalItemTypeId', 'number', null);
		this.quantity = getValue(dto, 'quantity', 'number', 1);
		this.items = getTypedArray(dto, 'items', CustomerRentalDetailedItem, []);
	}
}

export class CustomerRentalDetailedItem {
	constructor(dto) {
		this.rentalItemTypeId = getValue(dto, 'rentalItemTypeId', 'number', 0);
		this.rentalItemNumber = getValue(dto, 'rentalItemNumber', 'number', null);
		this.status = getValue(dto, 'status', 'number', 0);
	}
}

export class CustomerRentalDetailed {
	constructor(dto) {
		this.id = getValue(dto, 'id', 'number', 0);
		this.startDate = getDate(dto, 'startDate', null);
		this.endDate = getDate(dto, 'endDate', null);
		this.isStarted = getValue(dto, 'isStarted', 'boolean', false);
		this.isEnded = getValue(dto, 'isEnded', 'boolean', false);
		this.workOrders = getTypedArray(dto, 'workOrders', WorkOrderReference, []);
		this.needs = getTypedArray(dto, 'needs', CustomerRentalDetailedNeed, []);
	}
}
